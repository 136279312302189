import React from 'react'
import AboutUs from '@/features/about-us'
import { getHomePageProps } from '@/helper/home/home-seo'
import { t } from '@lingui/macro'
import { ThemeEnum } from '@/constants/base'
import HomePage from '@/features/home-page'
import { envIsCommon } from '@/helper/env'
import { document_title } from '@/constants/document-title'

export function Page(props: Awaited<ReturnType<typeof getHomePageProps>>) {
  return <HomePage />
}

export async function onBeforeRender(pageContext: PageContext) {
  const pageProps = {}
  const layoutParams = {
    footerShow: true,
    headerShow: true,
  }
  // const unAuthToParams = !envIsCommon
  //   ? {
  //       unAuthTo: '/wallet',
  //     }
  //   : {}
  return {
    pageContext: {
      // ...unAuthToParams,
      needSeo: true,
      pageProps,
      layoutParams,
      theme: ThemeEnum.dark,
      documentProps: {
        title: `${document_title}${t`modules_index_page_lmsdmyclrj`}`,
        description: t({
          id: 'modules_wallet_index_page_an29w50tp5',
          values: { 0: envIsCommon ? 'Bling Chat' : 'talkblink', 1: envIsCommon ? 'Bling Chat' : 'talkblink' },
        }),
      },
    },
  }
}
